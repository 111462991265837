import React from "react";
import { Container } from 'components/library';
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Sidebar } from './sidebar';
import { TranslationKey } from 'types/translation';

const items: TranslationKey[] = [
    "privacy-policy-page.sidebar.nav.1.items.personal-information",
    "privacy-policy-page.sidebar.nav.1.items.collect-personal-information",
    "privacy-policy-page.sidebar.nav.1.items.information-collected",
    "privacy-policy-page.sidebar.nav.1.items.polymerize-use-personal-information",
    "privacy-policy-page.sidebar.nav.1.items.share-personal-information",
    "privacy-policy-page.sidebar.nav.1.items.cookies-n-other-tools",
    "privacy-policy-page.sidebar.nav.1.items.what-choices-do-i-have",
    "privacy-policy-page.sidebar.nav.1.items.retension-of-data",
    "privacy-policy-page.sidebar.nav.1.items.secure-information",
    "privacy-policy-page.sidebar.nav.1.items.website-is-not-for-minor",
    "privacy-policy-page.sidebar.nav.1.items.third-party-sites",
    "privacy-policy-page.sidebar.nav.1.items.contact-polymerize",
    "privacy-policy-page.sidebar.nav.1.items.changes-to-privacy-policy",
]

export const PageContent = () => {
    const { t } = useTranslation()

    return (
        <Container className='flex flex-col gap-10 sm:gap-16 py-8 sm:py-24'>
            <h1 className="text-4xl tracking-wider sm:text-center sm:text-5xl md:text-7xl">
                {t('privacy-policy-page.heading')}
            </h1>

            <section className="items-start grid grid-cols-4 w-full gap-20">
                <Sidebar
                    nav1Title='privacy-policy-page.sidebar.nav.1.title'
                    nav2Title='privacy-policy-page.sidebar.nav.2.title'
                    items={items}
                />

                <article className="col-span-4 sm:col-span-3 prose-sm sm:prose-lg prose-h3:uppercase prose-td:border prose-td:p-2 prose-table:border prose-th:border prose-th:p-2 prose-th:text-left prose-th:font-medium">
                    <div className='flex items-center gap-8 justify-between scroll-mt-48 not-prose'>
                        <h2 id={t('common.words.privacy-policy')} className="text-3xl">
                            {t('common.words.privacy-policy')}
                        </h2>

                        <p className='text-sm'>
                            {t('privacy-policy-page.last-updated-at')}
                        </p>
                    </div>

                    {/* INTRODUCTION */}
                    <h3>
                        {t('privacy-policy-page.article.introduction.heading')}
                    </h3>
                    <p>{t('privacy-policy-page.article.introduction.paragraph.1')}</p>
                    <p>{t('privacy-policy-page.article.introduction.paragraph.2')}</p>

                    {/* #1 PERSONAL INFORMATION */}
                    <h3 id={t('privacy-policy-page.article.personal-information.heading')} className="scroll-mt-36">
                        1. {t('privacy-policy-page.article.personal-information.heading')}
                    </h3>
                    <div>(a) {t('privacy-policy-page.article.personal-information.paragraph.a.content')}
                        <ul>
                            <li>(i) {t('privacy-policy-page.article.personal-information.paragraph.a.i')}</li>
                            <li>(ii) {t('privacy-policy-page.article.personal-information.paragraph.a.ii')}</li>
                        </ul>
                    </div>

                    {/* #2 How does Polymerize collect personal information? */}
                    <h3 id={t('privacy-policy-page.article.collect-personal-information.heading').replace('?', '')} className="scroll-mt-36">
                        2. {t('privacy-policy-page.article.collect-personal-information.heading')}
                    </h3>
                    <p>(a) {t('privacy-policy-page.article.collect-personal-information.paragraph.a.content')}</p>
                    <p>
                        (b) {t('privacy-policy-page.article.collect-personal-information.paragraph.b.content')}
                    </p>

                    <div>
                        (c) {t('privacy-policy-page.article.collect-personal-information.paragraph.c.content')}
                        <ul>
                            <li>(i) {t('privacy-policy-page.article.collect-personal-information.paragraph.c.i')}</li>
                            <li>(ii) {t('privacy-policy-page.article.collect-personal-information.paragraph.c.ii')}</li>
                            <li>(iii) {t('privacy-policy-page.article.collect-personal-information.paragraph.c.iii')}</li>
                        </ul>
                        <span>{t('privacy-policy-page.article.collect-personal-information.paragraph.c.footer')}</span>
                    </div>
                    <p>
                        (d) {t('privacy-policy-page.article.collect-personal-information.paragraph.d.content')}
                    </p>
                    <div>
                        (e) {t('privacy-policy-page.article.collect-personal-information.paragraph.e.content')}
                        <ul>
                            <li>(i) {t('privacy-policy-page.article.collect-personal-information.paragraph.e.i')}</li>
                            <li>(ii) {t('privacy-policy-page.article.collect-personal-information.paragraph.e.ii')}</li>
                        </ul>
                    </div>

                    {/* #3 Information Collected */}
                    <h3 id={t('privacy-policy-page.article.information-collected.heading')} className="scroll-mt-36">
                        3. {t('privacy-policy-page.article.information-collected.heading')}
                    </h3>
                    <div>
                        (a) {t('privacy-policy-page.article.information-collected.paragraph.a.content')}
                        <ul>
                            <li>(i) {t('privacy-policy-page.article.information-collected.paragraph.a.i')}</li>
                            <li>(ii) {t('privacy-policy-page.article.information-collected.paragraph.a.ii')}</li>
                            <li>(iii) {t('privacy-policy-page.article.information-collected.paragraph.a.iii')}</li>
                            <li>
                                (iv) {t('privacy-policy-page.article.information-collected.paragraph.a.iv')}
                            </li>
                            <li>
                                (v) {t('privacy-policy-page.article.information-collected.paragraph.a.v')}
                            </li>
                            <li>
                                (vi) {t('privacy-policy-page.article.information-collected.paragraph.a.vi')}
                            </li>
                            <li>
                                (vii) {t('privacy-policy-page.article.information-collected.paragraph.a.vii')}
                            </li>
                            <li>
                                (viii) {t('privacy-policy-page.article.information-collected.paragraph.a.viii')}
                            </li>
                            <li>
                                (ix) {t('privacy-policy-page.article.information-collected.paragraph.a.ix')}
                            </li>
                            <li>
                                (x) {t('privacy-policy-page.article.information-collected.paragraph.a.x')}
                            </li>
                            <li>
                                (xi) {t('privacy-policy-page.article.information-collected.paragraph.a.xi')}
                            </li>
                            <li>
                                (xii) {t('privacy-policy-page.article.information-collected.paragraph.a.xii')}
                            </li>
                        </ul>
                    </div>

                    {/* #4 How does Polymerize use personal information? */}
                    <h3 id={t('privacy-policy-page.article.polymerize-use-personal-information.heading').replace('?', '')} className="scroll-mt-36">
                        4. {t('privacy-policy-page.article.polymerize-use-personal-information.heading')}
                    </h3>
                    <div>
                        (a) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.content')}

                        <ul>
                            <li>(i) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.i')}</li>
                            <li>(ii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.ii')}</li>
                            <li>(iii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.iii')}</li>
                            <li>(iv) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.iv')}</li>
                            <li>(v) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.v')}</li>
                            <li>(vi) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.vi')}</li>
                            <li>(vii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.vii')}</li>
                            <li>(viii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.viii')}</li>
                            <li>(ix) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.ix')}</li>
                            <li>(x) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.x')}</li>
                            <li>(xi) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xi')}</li>
                            <li>(xii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xii')}</li>
                            <li>(xiii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xiii')}</li>
                            <li>(xiv) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xiv')}</li>
                            <li>(xv) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xv')}</li>
                            <li>(xvi) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xvi')}</li>
                            <li>(xvii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xvii')}</li>
                            <li>(xviii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.a.xviii')}</li>
                        </ul>
                    </div>
                    <div>
                        (b) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.b.content')}

                        <ul>
                            <li>(i) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.b.i')}</li>
                            <li>(ii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.b.ii')}</li>
                            <li>(iii) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.b.iii')}</li>
                        </ul>
                    </div>
                    <p>
                        (c) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.c.content')}
                    </p>
                    <p>
                        (d) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.d.content')}
                    </p>
                    <p>
                        (e) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.e.content')}
                    </p>
                    <p>
                        (f) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.f.content')}
                    </p>
                    <p>
                        (g) {t('privacy-policy-page.article.polymerize-use-personal-information.paragraph.g.content')}
                    </p>

                    {/* #5 How does Polymerize share personal information? */}
                    <h3
                        id={t('privacy-policy-page.article.share-personal-information.heading').replace('?', '')}
                        className="scroll-mt-36"
                    >
                        5. {t('privacy-policy-page.article.share-personal-information.heading')}
                    </h3>
                    <p>
                        (a) {t('privacy-policy-page.article.share-personal-information.paragraph.a.content')}
                    </p>
                    <p>
                        (b) {t('privacy-policy-page.article.share-personal-information.paragraph.b.content')}
                    </p>
                    <p>
                        (c) {t('privacy-policy-page.article.share-personal-information.paragraph.c.content')}
                    </p>
                    <p>
                        (d) {t('privacy-policy-page.article.share-personal-information.paragraph.d.content')}
                    </p>
                    <p>
                        (e) {t('privacy-policy-page.article.share-personal-information.paragraph.e.content')}
                    </p>
                    <div>
                        (f) {t('privacy-policy-page.article.share-personal-information.paragraph.f.content')}
                        <ul>
                            <li>
                                (i) {t('privacy-policy-page.article.share-personal-information.paragraph.f.i')}
                            </li>
                            <li>
                                (ii) {t('privacy-policy-page.article.share-personal-information.paragraph.f.ii')}
                            </li>
                            <li>
                                (iii) {t('privacy-policy-page.article.share-personal-information.paragraph.f.iii')}
                            </li>
                            <li>
                                (iv) {t('privacy-policy-page.article.share-personal-information.paragraph.f.iv')}
                            </li>
                            <li>
                                (v) {t('privacy-policy-page.article.share-personal-information.paragraph.f.v')}
                            </li>
                        </ul>
                    </div>

                    {/* #6 Cookies and Other Tools */}
                    <h3
                        id={t('privacy-policy-page.article.cookies-n-other-tools.heading')}
                        className="scroll-mt-36"
                    >
                        6. {t('privacy-policy-page.article.cookies-n-other-tools.heading')}?
                    </h3>
                    <p>
                        (a) {t('privacy-policy-page.article.cookies-n-other-tools.paragraph.a.content')}
                    </p>
                    <p>
                        (b) {t('privacy-policy-page.article.cookies-n-other-tools.paragraph.b.content')}
                    </p>
                    <div>
                        (c) {t('privacy-policy-page.article.cookies-n-other-tools.paragraph.c.content')}

                        <ul>
                            <li>
                                (i) {t('privacy-policy-page.article.cookies-n-other-tools.paragraph.c.i')}
                            </li>
                            <li>
                                (ii) {t('privacy-policy-page.article.cookies-n-other-tools.paragraph.c.ii')}
                            </li>
                        </ul>
                    </div>
                    <p>
                        (d) {t('privacy-policy-page.article.cookies-n-other-tools.paragraph.d.content')}
                    </p>

                    {/* #7 WHAT CHOICES DO I HAVE ABOUT HOW POLYMERIZE COLLECTS AND USES INFORMATION ABOUT ME? */}
                    <h3
                        id={t('privacy-policy-page.article.what-choices-do-i-have.heading').replace('?', '')}
                        className="scroll-mt-36"
                    >
                        7. {t('privacy-policy-page.article.what-choices-do-i-have.heading')}
                    </h3>
                    <p>
                        (a) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.a.content')}
                    </p>
                    <div>
                        (b) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.b.content')}
                        <ul>
                            <li>
                                (i) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.b.i')}
                            </li>
                            <li>
                                (ii) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.b.ii')}
                            </li>
                            <li>
                                (iii) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.b.iii')}
                            </li>
                        </ul>
                    </div>
                    <div>
                        (c) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.content')}
                        <ul>
                            <span>
                                {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.internal-extra')}
                            </span>
                            <li>
                                (i) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.i')}
                            </li>
                            <li>
                                (ii) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.ii')}
                            </li>
                            <li>
                                (iii) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.iii')}
                                <ul>
                                    <li>
                                        (a) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.iii-a')}
                                    </li>
                                    <li>
                                        (b) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.iii-b')}
                                    </li>
                                    <li>
                                        (c) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.iii-c')}
                                    </li>
                                    <li>
                                        (d) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.iii-d')}
                                    </li>
                                    <li>
                                        (e) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.iii-e')}
                                    </li>
                                    <li>
                                        (f) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.c.iii-f')}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <p>
                        (d) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.d.content')}
                    </p>
                    <p>
                        (e) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.e.content')}
                    </p>
                    <p>
                        (f) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.f.content')}
                    </p>
                    <p>
                        (g) {t('privacy-policy-page.article.what-choices-do-i-have.paragraph.g.content')}
                    </p>

                    {/* #8 Retension of Data */}
                    <h3
                        id={t('privacy-policy-page.article.retension-of-data.heading')}
                        className="scroll-mt-36"
                    >
                        8. {t('privacy-policy-page.article.retension-of-data.heading')}
                    </h3>
                    <p>
                        {t('privacy-policy-page.article.retension-of-data.paragraph')}
                    </p>

                    {/* #9 HOW DOES POLYMERIZE SECURE INFORMATION? */}
                    <h3
                        id={t('privacy-policy-page.article.secure-information.heading').replace('?', '')}
                        className="scroll-mt-36"
                    >
                        9. {t('privacy-policy-page.article.secure-information.heading')}
                    </h3>
                    <p>
                        (a) {t('privacy-policy-page.article.secure-information.paragraph.a.content')}
                    </p>
                    <p>
                        (b) {t('privacy-policy-page.article.secure-information.paragraph.b.content')}
                    </p>
                    <p>
                        (c) {t('privacy-policy-page.article.secure-information.paragraph.c.content')}
                    </p>

                    {/* #10 Website is not for Minor */}
                    <h3
                        id={t('privacy-policy-page.article.website-is-not-for-minor.heading')}
                        className="scroll-mt-36"
                    >
                        10. {t('privacy-policy-page.article.website-is-not-for-minor.heading')}
                    </h3>
                    <p>
                        {t('privacy-policy-page.article.website-is-not-for-minor.paragraph')}
                    </p>

                    {/* #11 Third Party Sites  */}
                    <h3
                        id={t('privacy-policy-page.article.third-party-sites.heading')}
                        className="scroll-mt-36"
                    >
                        11. {t('privacy-policy-page.article.third-party-sites.heading')}
                    </h3>
                    <p>
                        (a) {t('privacy-policy-page.article.third-party-sites.paragraph.a.content')}
                    </p>
                    <p>
                        (b) {t('privacy-policy-page.article.third-party-sites.paragraph.b.content')}
                    </p>

                    {/* #12 HOW CAN I CONTACT POLYMERIZE? */}
                    <h3
                        id={t('privacy-policy-page.article.contact-polymerize.heading')}
                        className="scroll-mt-36"
                    >
                        12. {t('privacy-policy-page.article.contact-polymerize.heading')}
                    </h3>
                    <p>
                        (a) {t('privacy-policy-page.article.contact-polymerize.paragraph.a.content')}
                    </p>

                    <table>
                        <tr>
                            <th>NAME: </th>
                            <td>Vaibhav Mule</td>
                        </tr>
                        <tr>
                            <th>ADDRESS: </th>
                            <td>UEN 202013550M, 11 Irving Place, #09-01, Singapore 369551</td>
                        </tr>
                        <tr>
                            <th>TEL: </th>
                            <td>+65 31385586</td>
                        </tr>
                        <tr>
                            <th>EMAIL: </th>
                            <td>contact@polymerize.io</td>
                        </tr>
                    </table>

                    <p>
                        (b) {t('privacy-policy-page.article.contact-polymerize.paragraph.b.content')}
                    </p>
                    <div>
                        (c) {t('privacy-policy-page.article.contact-polymerize.paragraph.c.content')}
                        <ul>
                            <li>
                                (i) {t('privacy-policy-page.article.contact-polymerize.paragraph.c.i')}
                            </li>
                            <li>
                                (ii) {t('privacy-policy-page.article.contact-polymerize.paragraph.c.ii')}
                            </li>
                            <li>
                                (iii) {t('privacy-policy-page.article.contact-polymerize.paragraph.c.iii')}
                            </li>
                            <li>
                                (iv) {t('privacy-policy-page.article.contact-polymerize.paragraph.c.iv')}
                            </li>
                        </ul>
                    </div>

                    {/* #13 Changes to our privacy policy */}
                    <h3
                        id={t('privacy-policy-page.article.changes-to-privacy-policy.heading')}
                        className="scroll-mt-36"
                    >
                        13. {t('privacy-policy-page.article.changes-to-privacy-policy.heading')}
                    </h3>
                    <p>
                        {t('privacy-policy-page.article.changes-to-privacy-policy.paragraph')}
                    </p>
                </article>
            </section>
        </Container>
    )
};
