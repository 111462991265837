import React from "react"
import { graphql } from "gatsby"
import { PageContent } from "components/pages/privacy-policy/wrapper"

const PrivacyPolicy = () => {
  return (
    <PageContent />
  )
}

export default PrivacyPolicy

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 
